export const data=[
    {
        title:'Chatbots',
        text:'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum blanditiis, est necessitatibus delectu expedita, in'
    },

    {
        title:'Knowledgebase',
        text:'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum blanditiis, est necessitatibus delectu expedita, in'
    },

    {
        title:'Education',
        text:'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum blanditiis, est necessitatibus delectu expedita, in'
    }
]