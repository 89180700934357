export const data1=[
    {
        title:'Improving and distrusts instantly',
        text:'Lorem ipsum dolor sit amet  et magni aperiam rnatur voluptates tempore commodi fugiat, ex nesciunt pariatur ut dolorum molestias? Dolore?'
    },

    {
        title:'Become the Tended active',
        text:'Lorem ipsum dolor sit amet  et magni aperiam rnatur voluptates tempore commodi fugiat, ex nesciunt pariatur ut dolorum molestias? Dolore?'
    },

    {
        title:'Message or am nothing',
        text:'Lorem ipsum dolor sit amet  et magni aperiam rnatur voluptates tempore commodi fugiat, ex nesciunt pariatur ut dolorum molestias? Dolore?'
    },

    {
        title:'Really boy law county',
        text:'Lorem ipsum dolor sit amet  et magni aperiam rnatur voluptates tempore commodi fugiat, ex nesciunt pariatur ut dolorum molestias? Dolore?'
    }
]